import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import Layout from '../components/layout'
import SEO from '../components/seo'

import PageHeader from '../components/PageHeader'

const query = graphql`
  query {
    prismic {
      page: contact_page(uid: "contact-us", lang: "en-us") {
        title
        description
        addresses {
          name
          email
          phone_number
        }
      }
    }
  }
`

const ContactPage = () => (
  <StaticQuery
    query={query}
    render={withPreview(data => {
      const { page } = data.prismic
      return (
        <Layout>
          <SEO title={page.title} keywords={[`longevity`]} />

          <PageHeader
            titles={[page.title]}
            richDescription={page.description}
          />

          <div className="container mb-24 lg:mb-32 lg:max-w-xl px-8 lg:px-0 mx-auto font-serif text-blue-dianne">
            <div className="lg:flex mt-16">
              <div className="lg:w-2/3 lg:flex flex-no-shrink lg:pr-4">
                {page.addresses.map((address, index) => (
                  <div className="mt-4" key={index}>
                    <p className="font-sans">{address.name}</p>
                    <p className="font-sans mt-4">
                      E:{' '}
                      <a
                        className="no-underline text-blue-dianne hover:underline"
                        href={`mailto:${address.email}`}
                      >
                        {address.email}
                      </a>
                    </p>
                    <p className="font-sans mt-4">
                      M:{' '}
                      <a
                        className="no-underline text-blue-dianne"
                        href={`tel:${address.phone_number}`}
                      >
                        {address.phone_number}
                      </a>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Layout>
      )
    }, query)}
  />
)

export default ContactPage
